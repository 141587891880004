
import {defineComponent, ref} from 'vue';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import {DefaultConversions} from '@/models/Media';
import Errors from '@/library/model-collection/Errors';
import FormGroup from '@/components/common/form/FormGroup.vue';
import ImageField from '@/components/common/form/ImageField.vue';
import User from '@/models/User';
import auth from '@/store/auth';
import axios from 'axios';

export default defineComponent({
    components: {
        BtnResource,
        FormGroup,
        ImageField,
    },
    setup() {
        const newUser = new User();

        return {
            user: ref(newUser),
            newProfilePicture: ref<string>(''),
            errors: ref(new Errors()),
        };
    },
    computed: {
        image(): string | DefaultConversions | undefined {
            return this.newProfilePicture || this.user.profilePicture;
        },
    },
    methods: {
        async saveProfile() {
            this.errors = new Errors();

            const data: Record<string, any> = {
                name: this.user.name,
            };

            if (this.newProfilePicture) {
                data.profilePicture = this.newProfilePicture;
            }

            try {
                this.user.loading = true;

                const response = await axios.post('/demo', data);

                this.user.success = true;

                auth.setApiToken(response.data.apiToken);
                auth.setMe(response.data.user);

                let route = {
                    name: 'home',
                } as Record<string, any>;

                if (response.data.pitch) {
                    route = {
                        name: 'pitches.edit',
                        params: {
                            slug: response.data.pitch.slug,
                        },
                    };
                }

                await this.$router.push(route);
            } catch (e: any) {
                this.user.error = true;

                if (!e.response || !e.response.data.errors) throw e;

                this.errors.setErrors(e.response.data.errors);

                if (e.response.status === 400) {
                    if (!this.errors.errors) {
                        this.errors.errors = {};
                    }

                    this.errors.errors.noTeam = [e.response.data.message];
                }
            }

            this.user.loading = false;
        },
    },
});
