import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "text-center mt-6 text-black-50" }
const _hoisted_3 = { class: "d-grid mt-12" }
const _hoisted_4 = {
  key: 0,
  class: "invalid-feedback d-block mt-6 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageField = _resolveComponent("ImageField")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_BtnResource = _resolveComponent("BtnResource")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('profile.create.title')), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('profile.create.body')), 1),
    _createElementVNode("form", {
      class: "mt-12",
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.saveProfile && _ctx.saveProfile(...args)), ["prevent"]))
    }, [
      _createVNode(_component_ImageField, {
        class: "mt-12",
        errors: _ctx.errors,
        label: _ctx.$t('profile.picture'),
        "model-value": _ctx.image,
        name: "profile-picture",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.newProfilePicture = $event))
      }, null, 8, ["errors", "label", "model-value"]),
      _createVNode(_component_FormGroup, {
        modelValue: _ctx.user.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.name) = $event)),
        class: "mt-12",
        error: _ctx.errors.errors.name && _ctx.errors.errors.name[0],
        label: _ctx.$t('profile.name'),
        name: "name"
      }, null, 8, ["modelValue", "error", "label"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BtnResource, { resource: _ctx.user }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('actions.next')), 1)
          ]),
          _: 1
        }, 8, ["resource"])
      ]),
      (_ctx.errors.errors.noTeam && _ctx.errors.errors.noTeam[0])
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.errors.errors.noTeam[0]), 1))
        : _createCommentVNode("", true)
    ], 32)
  ]))
}